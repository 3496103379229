// TODO: 目前對話只先支援 英文, 法文, 西班牙文, 中文, 日文,.
export const LANGUAGE = [
  // {
  //   key: 'ar',
  //   value: 'ar',
  //   name: 'اللغة العربية',
  // },
  // {
  //   key: 'de',
  //   value: 'de',
  //   name: 'Deutsch',
  // },
  {
    key: 'en',
    value: 'en',
    name: 'English',
  },
  {
    key: 'es',
    value: 'es',
    name: 'Español',
  },
  {
    key: 'fr',
    value: 'fr',
    name: 'Français',
  },
  // {
  //   key: 'hi',
  //   value: 'hi',
  //   name: 'हिंदी',
  // },
  // {
  //   key: 'id',
  //   value: 'id',
  //   name: 'Bahasa Indonesia',
  // },
  // {
  //   key: 'it',
  //   value: 'it',
  //   name: 'Italiano',
  // },
  {
    key: 'ja',
    value: 'ja',
    name: '日本語',
  },
  {
    key: 'ko',
    value: 'ko',
    name: '한국어',
  },
  // {
  //   key: 'ms',
  //   value: 'ms',
  //   name: 'Bahasa Melayu',
  // },
  // {
  //   key: 'pl',
  //   value: 'pl',
  //   name: 'Polski',
  // },
  // {
  //   key: 'pt',
  //   value: 'pt',
  //   name: 'Português',
  // },
  // {
  //   key: 'ru',
  //   value: 'ru',
  //   name: 'Русский',
  // },
  // {
  //   key: 'th',
  //   value: 'th',
  //   name: 'ภาษาไทย',
  // },
  // {
  //   key: 'tr',
  //   value: 'tr',
  //   name: 'Türkçe',
  // },
  // {
  //   key: 'vi',
  //   value: 'vi',
  //   name: 'Tiếng Việt',
  // },
  {
    key: 'zh-CN',
    value: 'zh-CN',
    name: '简体中文',
  },
  {
    key: 'zh-TW',
    value: 'zh-TW',
    name: '繁體中文',
  },
]

export const locales = LANGUAGE.map(l => l.value)

export const DEFAULT_LANGUAGE = 'en'

type Resources = Record<string, { translation?: string; redirect?: string }>

export const LANGUAGE_RESOURCES: Resources = {
  en: {
    translation: 'en',
  },
  ar: {
    translation: 'ar',
  },
  de: {
    translation: 'de',
  },
  es: {
    translation: 'es',
  },
  fr: {
    translation: 'fr',
  },
  hi: {
    translation: 'hi',
  },
  id: {
    translation: 'id',
  },
  it: {
    translation: 'it',
  },
  ja: {
    translation: 'ja',
  },
  jp: {
    redirect: 'ja',
  },
  ko: {
    translation: 'ko',
  },
  ms: {
    translation: 'ms',
  },
  pl: {
    translation: 'pl',
  },
  pt: {
    translation: 'pt',
  },
  ru: {
    translation: 'ru',
  },
  th: {
    translation: 'th',
  },
  tr: {
    translation: 'tr',
  },
  vi: {
    translation: 'vi',
  },
  zh: {
    redirect: 'zh-TW',
  },
  'zh-CN': {
    translation: 'zh-CN',
  },
  'zh-Hans': {
    redirect: 'zh-CN',
  },
  'zh-TW': {
    translation: 'zh-TW',
  },
  'zh-Hant': {
    redirect: 'zh-TW',
  },
}

export const LOCALE_COOKIE_NAME = 'lang'
export const LOCALE_HEADER_NAME = 'X-LANG'
export const LOCALE_QUERY_NAME = 'hl'
