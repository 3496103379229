const HELP = '/help'
const EMBED = '/embed'

export const ROUTE_PATH = {
  ROOT: '/',
  CONTACT: '/contact',
  PLANS: '/plans',
  ABOUT: '/about',
  VERIFY_EMAIL: '/verify-email',
  DOWNLOAD: '/download',
  AUTH: '/auth',
  ACCOUNT: '/account',
  TERMS: {
    PRIVACY_POLICY: '/privacy-policy',
    PALUP_RULES: '/palup-rules',
    USER_TERMS_OF_SERVICE: '/user-terms-of-service',
  },
  HELP: {
    INDEX: HELP,
    GENERAL: `${HELP}/general`,
    ACCOUNT: `${HELP}/account`,
    USING_PALUP: `${HELP}/using-palup`,
    REPORT: `${HELP}/report`,
    PRIVACY_SAFETY: `${HELP}/privacy-safety`,
    INTELLECTUAL_PROPERTY: `${HELP}/intellectual-property`,
    DMCA: `${HELP}/dmca`,
  },
  SIDEBAR: { FEED: '/feed', DISCOVER: '/discover', MESSAGE: '/messages' },
  EMBED: {
    PAYMENT_METHOD: `${EMBED}/payment-method`,
    LANDING: { RECHARGE: `${EMBED}/landing/recharge` },
    RECHARGE: `${EMBED}/recharge`,
    CONTACT_SALES: `${EMBED}/contact-sales`,
  },
}
