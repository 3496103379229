import React from 'react'

export const HASH_KEY = {
  AUTH_LOGIN: 'auth-login',
  DOWNLOAD_APP: 'download-app',
} as const

function subscribe(listener: () => void) {
  window.addEventListener('hashchange', listener)
  return () => {
    window.removeEventListener('hashchange', listener)
  }
}

function getHash() {
  return window.location.hash
}

function getServerHash() {
  return ''
}

export function useHash() {
  return React.useSyncExternalStore(subscribe, getHash, getServerHash)
}
